import React from 'react';
import '../css/payment.scss';
import { useNavigate } from 'react-router-dom'; 
import Header from "./Header";

const SuccessPage = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook

    // Function to handle button click
    const navigateToChat = () => {
      navigate('/chatbot'); // Navigate to /chatbot page
    };
  

  return (

    <div className="success-page">
         <Header />
      <div className="success-card">
        <div className="success-icon">
          <i className="checkmark">✓</i>
        </div>
        <h2>Payment Success</h2>
        <p>Thanks for your order!</p>
        <button className="start-btn" onClick={navigateToChat}>Chat with Psyfy</button>
      </div>
    </div>
  );
}

export default SuccessPage;
