import React from "react";
import Header from "./Header";
import LoginForm from "./LoginForm";
import "../css/loginstyle.scss";
import FrontPageFooter from "./FrontPageFooter";

const LoginPage = () => {
  return (
    
      <div className="header">
        <Header />
        <div className="login-page-container">
        <div className="form-and-chat">
          <LoginForm />
        </div>
      </div>
      <div className="footer-container">
        <FrontPageFooter />
      </div>
    </div>
  );
};

export default LoginPage;
