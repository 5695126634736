import React from "react";
import Header from "./Header";
import MainTitle from "./MainTitle";
import Waves from "./Waves";
import IntroBlocks from "./DesignIntro";
import FrontPageFooter from "./FrontPageFooter";

const HomePageHeader = () => {
  return (
    <div className="main-page">
      <div className="header">
        <Header />
      </div>
      <MainTitle />
      <Waves />
      <IntroBlocks />
      <FrontPageFooter />
    </div>
  );
};

export default HomePageHeader;
