import React, { useState, useEffect } from "react";
import Header from "./Header";
import {usePsyfyClient} from "../hooks/psyfyClient";
import Logger from "../utils/logger";

const PasswordResetRequestForm = () => {
  const { requestPasswordReset} = usePsyfyClient();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    Logger.debug(isSubmitted); 
  }, [isSubmitted]); 

  useEffect(() => {
    if (buttonDisabled) {
      const timer = setTimeout(() => {
        setButtonDisabled(false);
      }, 300000); // 300000 ms = 5 minutes
      return () => clearTimeout(timer);
    }
  }, [buttonDisabled]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonDisabled(true); // Disable button when submitting
    setIsSubmitted(false); // Reset the submitted state if needed

    try {
 
      const response = await requestPasswordReset(email);
      if (response.data.success) {

        setMessage(response.data.message);
        setIsSubmitted(true);
      } else {
     
        setMessage(
          response.data.message ||
            "Error sending reset link. Please try again later."
        );

        setMessage(response.data.message);
        setIsSubmitted(true);
      }
    } catch (error) {

      if (error.response) {
        Logger.debug("Server responded with an error:", error.response);
        setMessage(
          error.response.data.message ||
            "An unexpected error occurred. Please try again later."
        );
      } else if (error.request) {
        // The request was made but no response was received
        Logger.debug("No response received:", error.request);
        setMessage(
          "No response from the server. Please check your internet connection and try again."
        );
      } else {
        // Something else caused the error
        Logger.debug("Error setting up the request:", error.message);
        setMessage(
          "An error occurred setting up your request. Please try again later."
        );
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="reset-form-container">
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            className="input-field"
            placeholder="Enter your email"
            value={email}
            disabled={isSubmitted}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            type="submit"
            className="submit-button"
            //disabled={isSubmitted}
            disabled={buttonDisabled}
          >
            Send Reset Link
          </button>
        </form>
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
};

export default PasswordResetRequestForm;
